import { t } from '../../languages';
import { IconButton } from "@mui/material";
import Grow from "@mui/material/Grow";
import { isNull } from "lib/utils";
import { SnackbarProvider, useSnackbar, ProviderContext } from "notistack";
import { useEffect } from "react";
import create from "zustand";
import CloseIcon from "@mui/icons-material/Close";

interface SnackbarStoreState {
  context: ProviderContext | null;
  setContext: (v: ProviderContext | null) => void;
}

const useSnackbarStore = create<SnackbarStoreState>((set) => ({
  context: null,
  setContext: (v) => set({ context: v }),
}));

function SnackbarEffect() {
  const snackbarContext = useSnackbar();
  const { setContext } = useSnackbarStore();

  useEffect(() => {
    setContext(snackbarContext);

    return () => setContext(null);
  }, [setContext, snackbarContext]);

  return <></>;
}

export const toastSnackbar: ProviderContext["enqueueSnackbar"] = (message, options) => {
  const snackbarContext = useSnackbarStore.getState().context;

  if (isNull(snackbarContext)) {
    throw new Error("Snackbar 未绑定");
  }

  const { enqueueSnackbar } = snackbarContext;

  const key = enqueueSnackbar(message, options);

  return key;
};

export default function SnackbarStack() {
  return (
    // 消息条
    <>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Grow}
        autoHideDuration={3000}
        action={(key) => (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => useSnackbarStore.getState().context?.closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      >
        <SnackbarEffect />
      </SnackbarProvider>
    </>
  );
}
