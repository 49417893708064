import { t } from '../../languages';
import SnackbarStack from "./SnackbarStack";

export { toastSnackbar } from "./SnackbarStack";

export default function AppFeedback() {
  return (
    <>
      {/*  消息条 */}
      <SnackbarStack />
    </>
  );
}
