import { t } from './';
export default {
    "1": "登录失效，请重新登录。",
    "2": "自动匹配经典录音版本一键听，",
    "3": "边听大师演绎边读谱，细节处找差距",
    "4": "曲集-乐谱-声部-乐章层级清晰",
    "5": "预置六大专业分类标签，快速精准筛选查找",
    "6": "根据排练和演出需求，随心创建、编辑和分享谱单",
    "7": "功能强大的标注、录音、节拍器等辅助工具，还支持将录制",
    "8": "的演奏音频和乐谱注释文件分享给其他乐手或师生",
    "9": "首页",
    "10": "产品介绍",
    "11": "关于我们",
    "12": "用户",
    "13": "退出",
    "14": "登录",
    "15": "导入乐谱",
    "16": "纸谱一键轻松拍（自动框选、自动正反、变形校正）",
    "17": "专治乐谱画质疑难杂症，一键去噪、去阴影、自动亮度白平衡",
    "18": "全终端导入（手机相册、手机文件、平板文件、电脑文件……）",
    "19": "全格式支持（jpg、png、pdf、xml、midi、……）",
    "20": "解析封面",
    "21": "上传封面",
    "22": "生成曲谱",
    "23": "上传 pdf",
    "24": "上传失败",
    "25": "仅支持上传 PDF 文件",
    "26": "导入",
    "27": "已导入",
    "28": "完成",
    "29": "取消",
    "30": "乐谱导入成功",
    "31": "请在APP「导入」-",
    "32": "「文件」中浏览",
    "33": "上传中",
    "34": "正在上传",
    "35": "服务器开小差了"
}