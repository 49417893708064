import { t } from '../languages';
import styled from "@emotion/styled";
import { Fade, Modal } from "@mui/material";
import { ReactNode, useCallback, useEffect, useState } from "react";
import zustand from "zustand";

const StyledToast = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 10px 8px;
  background: rgba(34, 34, 34, 0.7);
  border-radius: 1px;
  opacity: 0.7;

  border-radius: 1px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  outline: none;
`;

export function useTextToast(open = false) {
  const [props, setProps] = useState<Props>({
    open,
    onClose: () => setProps((prev) => ({ ...prev, open: false })),
  });

  const handleProps = useCallback(
    (props: Props) => {
      setProps((prev) => ({ ...prev, ...props }));
    },
    [setProps]
  );

  return [props, handleProps] as const;
}

interface Props {
  open?: boolean;
  onClose?(): any;
  time?: number;
  children?: React.ReactNode;
}

export type TextToastFeedbackProps = Props;

/** 文本 toast 反馈 */
export default function TextToastFeedback(props: Props) {
  const { time = 1500, open = false, onClose } = props;

  // 开启后两秒关闭
  useEffect(() => {
    if (!open) return;

    const timer = setTimeout(() => onClose?.(), time);
    return () => clearTimeout(timer);
  }, [open, onClose, time]);

  return (
    <Modal
      open={open}
      {...props}
      hideBackdrop
      sx={{ pointerEvents: "none" }}
      disableAutoFocus
      disableRestoreFocus
      disableEnforceFocus
    >
      <Fade in={open}>
        <StyledToast style={{ pointerEvents: "auto", cursor: "pointer" }} onClick={onClose}>
          {props.children}
        </StyledToast>
      </Fade>
    </Modal>
  );
}

/** 全局文本 toast 状态 */
interface TextToastFeedbackState {
  /** 是否展示 */
  opened: boolean;
  /** 打开 */
  open(n: ReactNode): void;
  /** 关闭 */
  close(): void;

  /** 渲染节点 */
  node: ReactNode;
  /** 设置渲染节点 */
  setNode(n: ReactNode): void;

  /** 时间 */
  time?: number;
  /** 设置时间 */
  setTime(t: number): void;
}

/** 全局文本反馈控制 */
export const useTextToastFeedbackStore = zustand<TextToastFeedbackState>((set) => ({
  opened: false,
  open: (n) => set({ opened: true, node: n }),
  close: () => set({ opened: false }),

  node: "",
  setNode: (n) => set({ node: n }),

  time: undefined,
  setTime: (time) => set({ time }),
}));

/** 全局文本反馈 使用 @see {@link useTextToastFeedbackStore } 控制 */
export function TextToastFeedbackGlobal() {
  const store = useTextToastFeedbackStore();
  return <TextToastFeedback open={store.opened} onClose={store.close} time={store.time} children={store.node} />;
}
