import { t } from './languages';
import "./App.css";
import { RouterProvider } from "react-router-dom";
import { routes } from "./router";
import { Suspense, useEffect } from "react";
import LoadingPage from "components/LoadingPage";
import { isDevelopment, isOverseas, isTest } from "lib/utils";
import AppFeedback from "components/AppFeedback";
import { toastSnackbar } from "components/AppFeedback/SnackbarStack";
import { i18nScope } from "languages";
import { VoerkaI18nProvider } from "@voerkai18n/react"
import { gapi } from 'gapi-script';

gapi.load('client:auth2', () => {
  gapi.client.init({
      clientId: process.env.REACT_APP_GG_APP_ID,
      plugin_name: 'chat',
  });
});
// import VConsoleDebug from "lib/VConsoleDebug";
// VConsoleDebug();

isDevelopment() && console.log(process.env);

function App() {
  useEffect(() => {

    isTest() && toastSnackbar("测试");

    if (isOverseas()) {
      VoerkaI18n.change("en")
      document.title = "Joyin"
      // const metaTag = document.querySelector('head > meta[name="description"]')
      // if (metaTag) {
      //   metaTag.setAttribute('content', "Sheet Music");
      // } else {
      //   console.error("未找到指定的 <meta> 标签");
      // }
    } else {
      document.title = "乐其爱乐谱"
    }
  }, []);

  return (
    <>
      <VoerkaI18nProvider scope={i18nScope} fallback={<div>loading...</div>}>
        <AppFeedback />
        <Suspense fallback={<LoadingPage />}>

          <RouterProvider router={routes} />

        </Suspense>
      </VoerkaI18nProvider>
    </>
  );
}

export default App;
